@import './styles/_vars.scss';

.App {
    min-height: 100vh;
    background-color: $color-bg-primary;
    display: flex;
    flex-direction: row;
    color: $color-text-primary;
    padding-left: 6rem;
    padding-right: 6rem;

    @media (max-width: $desktop-width) {
        flex-direction: column;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    @media (max-width: $tab-width) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.Bio {
    width: 40%;
    padding-top: 6rem;
    padding-bottom: 6rem;
    padding-right: 1rem;
    display: flex;

    header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-height: calc(100vh - 12rem);
        position: sticky;
        top: 6rem;
    }

    @media (max-width: $desktop-width) {
        width: 100%;
        padding-top: 5rem;
        padding-bottom: 0rem;
    }

    @media (max-width: $tab-width) {
        padding-top: 3rem;
    }
}

.Profile {
    width: 60%;
    padding-top: 6rem;
    padding-bottom: 6rem;

    @media (max-width: $desktop-width) {
        width: 100%;
        padding-bottom: 4rem;
    }

    @media (max-width: $tab-width) {
        padding-bottom: 3rem;
    }
}

.Introduction {
    h1 {
        color: $color-text-primary;
        margin: 0;
        font-size: 3rem;
        font-weight: bold;
        line-height: 1;

        @media (max-width: $mobile-width) {
            font-size: 2.25rem;
        }
    }

    h2 {
        color: $color-text-secondary;
        margin-top: 0.75rem;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.75rem;
    }

    p {
        color: $color-text-tertiary;
        line-height: 1.5rem;
        max-width: 20rem;
        white-space: nowrap;
    }
}

.Navbar {
    display: flex;
    flex-direction: column;

    a {
        display: flex;
        align-items: center;
        color: $color-text-quaternary;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        text-decoration: none;

        .indicator {
            display: inline-block;
            border-bottom: 1px solid $color-text-quaternary;
            height: 1px;
            width: 2rem;
            margin-right: 1rem;
            transition-property: all;
            transition-timing-function: cubic-bezier(.4, 0, .2, 1);
            transition-duration: .15s;
        }
    }

    a:hover,
    a.active {
        color: $color-text-secondary;
        cursor: pointer;

        .indicator {
            height: 2px;
            width: 4rem;
            border-color: $color-text-secondary;
        }
    }

    @media (max-width: $desktop-width) {
        display: none;
    }
}

.Socials {
    display: flex;
    margin-bottom: 0rem;
    align-items: center;
    padding-inline-start: 0px !important;

    a {
        color: $color-text-quaternary;
        margin-right: 1.25rem;

        svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    a:hover {
        color: $color-text-secondary;
        cursor: pointer;
    }
}

.title {
    z-index: 100;
    background: hsla(0, 0%, 4%, .2);
    backdrop-filter: blur(24px) saturate(140%);
    margin-bottom: 1.00rem;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.15rem;

    position: sticky;
    top: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-left: 3rem;
    padding-right: 3rem;
    margin-left: -3rem;
    margin-right: -3rem;

    @media (min-width: $desktop-width) {
        display: none;
    }

    @media (max-width: $tab-width) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
}

section {
    scroll-margin-top: 6rem;
    margin-bottom: 6rem;

    @media (max-width: $desktop-width) {
        margin-bottom: 4rem;
    }

    p {
        color: $color-text-tertiary;
        line-height: 1.5rem;
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    ol {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    // ol .Accordion:hover {
    //     @media (min-width: $tab-width) {
    //         opacity: 1;
    //     }
    // }
}

// main:hover .Accordion {
//     @media (min-width: $tab-width) {
//         opacity: 0.3;
//     }
// }

.Accordion {
    display: flex;
    flex-direction: row;
    border-radius: 12px;
    padding: .75rem;
    margin-bottom: 1rem;
    white-space: nowrap;

    @media (max-width: $desktop-width) {
        background: none;
        padding: 0rem 0rem 0.75rem 0rem;
    }

    @media (max-width: $tab-width) {
        flex-direction: column;
    }

    .header {
        color: $color-text-quaternary;
        padding-right: 1rem;
        white-space: nowrap;
        font-size: .75rem;
        font-weight: 600;
        line-height: 1rem;
        letter-spacing: .025em;
        text-transform: uppercase;

        @media (max-width: $desktop-width) {
            margin-bottom: 1rem;
        }
    }

    .header:empty {
        display: none;
    }

    .body {
        white-space: break-spaces;

        span {
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            a {
                color: $color-text-primary;
                text-decoration: none;
                position: relative;

                svg {
                    position: absolute;
                    bottom: 0;
                    height: 16px;
                    width: 16px;
                    transition-property: transform;
                    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
                    transition-duration: .15s;
                    transform: translate(0rem, 1px) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
                }
            }
        }

        p {
            color: $color-text-tertiary;
            font-size: .925rem;
            line-height: 1.35rem;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
        }

        ul {
            list-style: none;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            opacity: 0.75;

            li {
                margin-top: .25rem;
                margin-right: .375rem;
                padding: 0.5rem 0.5rem;
                border-radius: 18px;
                background-color: $icon-bg;
                color: $icon-text;
                font-size: 13px;
                font-weight: 500;
                white-space: nowrap;
            }
        }
    }
}

.Accordion:hover {
    // cursor: pointer;

    .body span,
    .body span a {
        color: $icon-text;
    }

    .body span svg {
        transform: translate(0.25rem, 1px) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
        transform: translateX(0.25rem) translateY(-0.25rem);
    }
}

footer {
    color: $color-text-tertiary;
    font-size: .875rem;
    line-height: 1.25rem;
    max-width: 28rem;

    p {
        margin: 0
    }
}

.rotate-word {
    display: contents;
    color: yellow;
}

.rotate-word span {
    padding-left: 5px;
    position: absolute;
    overflow: hidden;
    animation: rotate-animate 20s linear infinite;
    opacity: 0;
}

.rotate-word span:nth-child(0) {
    animation-delay: 0s;
}

.rotate-word span:nth-child(1) {
    animation-delay: 5s;
    color: $theme-blue
}

.rotate-word span:nth-child(2) {
    animation-delay: 10s;
    color: $theme-yellow;
}

.rotate-word span:nth-child(3) {
    animation-delay: 15s;
    color: $theme-green;
}

.rotate-word span:nth-child(4) {
    animation-delay: 20s;
    color: $theme-red;
}

@keyframes rotate-animate {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }

    2% {
        opacity: 1;
        transform: translateY(0px);
    }

    18% {
        opacity: 1;
        transform: translateY(0px);
    }

    20% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 0;
        transform: translateY(50px);
    }
}