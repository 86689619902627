$header-bg: hsla(0, 0%, 4%, .8);
$header-border: hsla(0, 0%, 100%, .08);
$color-bg-primary: #08090a;
$color-bg-secondary: #161616;
$color-bg-tertiary: #1c1c1c;
$color-bg-quaternary: #232323;
$color-bg-quinary: #282828;
$color-bg-6: #101112;
$color-border-primary: #23252a;
$color-border-secondary: #292b30;
$color-border-tertiary: #2c2e33;
$color-text-primary: #fff;
$color-text-secondary: #e3e4e6;
$color-text-tertiary: #969799;
$color-text-quaternary: #595a5c;
$color-link-primary: #7281ff;
$color-link-hover: #fff;
$color-brand-bg: #5e69d1;
$color-brand-text: #fff;
$color-selection-bg: #474747;
$color-selection-text: #fff;
$color-selection-dim: hsla(0, 0%, 100%, .2);
$focus-ring-color: hsla(0, 0%, 100%, .4);
$color-overlay-dim-rgb: 255, 255, 255;
$color-overlay-primary: rgba(0, 0, 0, .4);
$color-alpha: 255;
$shadow-low: 0px 2px 4px rgba(0, 0, 0, .1);
$shadow-medium: 0px 4px 24px rgba(0, 0, 0, .2);
$shadow-high: 0px 7px 32px rgba(0, 0, 0, .35);
$shadow-stack-low: 0px -1px 1px 0px rgba(0, 0, 0, .11) inset, 0px 8px 2px 0px transparent, 0px 5px 2px 0px rgba(0, 0, 0, .01), 0px 3px 2px 0px rgba(0, 0, 0, .04), 0px 1px 1px 0px rgba(0, 0, 0, .07), 0px 0px 1px 0px rgba(0, 0, 0, .08);
$icon-grayscale-image-filter: grayscale(100%) brightness(400%);
$icon-text: #68CC58;
$icon-bg: #172217;

/* Other Colors */
$theme-blue: #4594DF;
$theme-yellow: #FFCD16;
$theme-green: #00CD1C;
$theme-red: #EB5757;

$desktop-width: 1024px;
$tab-width: 768px;
$mobile-width: 640px;